import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ApiErrorMessage, LoadingScreen, NoddiContainer, NoddiSelect, NoddiTabs, TabItem } from 'noddi-ui';
import { NumberParam, useQueryParam } from 'use-query-params';

import StorageUnitList from './StorageUnitList';

type StorageUnitTabValues = 'occupied' | 'free';

const ramstad24Id = 1;

const StorageUnites = () => {
  const [storageId, setStorageId] = useQueryParam('storageId', NumberParam);

  const { data, error, isPending } = noddiAsync.useGet({
    type: URLKeys.getPaginatedStorageFacilities,
    input: { page: 1, pageSize: 1000 }
  });
  if (error) {
    return <ApiErrorMessage error={error} />;
  }
  if (isPending) {
    return <LoadingScreen />;
  }

  const storageFacilityId = storageId ?? ramstad24Id;
  const tabItems: TabItem<StorageUnitTabValues>[] = [
    {
      label: t`Free`,
      value: 'free',
      node: <StorageUnitList showUnoccupied storageFacilityId={storageFacilityId} />
    },
    {
      label: t`Occupied`,
      value: 'occupied',
      node: <StorageUnitList showUnoccupied={false} storageFacilityId={storageFacilityId} />
    }
  ];

  return (
    <NoddiContainer header={t`Storage units`}>
      <div>
        <NoddiSelect
          options={data.results.map((facility) => ({
            label: facility.name,
            value: facility.id
          }))}
          fullWidth
          label={t`Storage units`}
          value={storageFacilityId}
          onChange={(e) => {
            setStorageId(e.target.value as number);
          }}
        />
      </div>
      <NoddiTabs<StorageUnitTabValues> tabItems={tabItems} defaultValue='free' />
    </NoddiContainer>
  );
};

export default StorageUnites;
