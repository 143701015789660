import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { ThemeProvider } from '@mui/material/styles';
import { noddiAsync } from 'noddi-async';
import {
  AuthProvider,
  LanguageProvider,
  NoddiLocalizationProvider,
  ToastProvider,
  useAuthContext
} from 'noddi-provider';
import { ErrorPage, NoddiCircularLoader, PrivateLayout, createTheme } from 'noddi-ui';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { initLocales } from '../../../packages/config';
import { GlobalPickingList } from './Pages/GlobalPickingList';
import Login from './Pages/Login/Login';
import { PrintLabelWrapper } from './Pages/PrintLabels';
import PrintLabelRegnumber from './Pages/PrintLabelsByRegnumber';
import Profile from './Pages/Profile';
import StorageUnits from './Pages/StorageUnits';
import TranslatedVerticalLayout from './TranslatedVericalLayout';
import routes from './appRoutes';
import { dynamicActivateLocale } from './utils/lingui';

const defaultLanguage = 'en';

export const getSupportedLocales = () => ({
  en: { label: t`English`, flag: '🇬🇧' },
  nb: { label: t`Norwegian`, flag: '🇳🇴' }
});

function App() {
  const theme = createTheme();

  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);

  useEffect(() => {
    initLocales({
      setIsLanguageLoaded,
      defaultLanguage,
      dynamicActivateLocale,
      supportedLocales: getSupportedLocales()
    });
  }, []);

  if (!isLanguageLoaded) {
    return null;
  }

  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <I18nProvider i18n={i18n}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify
          }}
        >
          <NoddiServerContext>
            <AuthProvider>
              <LanguageProvider defaultLocale={defaultLanguage} dynamicActivateLocale={dynamicActivateLocale}>
                <ThemeProvider theme={theme}>
                  <ToastProvider>
                    <NoddiLocalizationProvider>
                      <Router />
                    </NoddiLocalizationProvider>
                  </ToastProvider>
                </ThemeProvider>
              </LanguageProvider>
            </AuthProvider>
          </NoddiServerContext>
        </QueryParamProvider>
      </I18nProvider>
    </ErrorBoundary>
  );
}

const Router = () => {
  const { isTokenLoginLoading } = useAuthContext();

  if (isTokenLoginLoading) {
    return (
      <div className='flex min-h-screen justify-center bg-pint-to-lighterPint-gradient'>
        <NoddiCircularLoader />
      </div>
    );
  }
  return (
    <div className='h-full'>
      <Routes>
        <Route path={routes.login.getBasePath()} element={<Login />} />
        <Route element={<PrivateLayout />}>
          <Route element={<TranslatedVerticalLayout />}>
            <Route path='/' element={<PrintLabelWrapper />} />
            <Route path={routes.printLabelsLicencePlateNumber.getBasePath()} element={<PrintLabelRegnumber />} />
            <Route path={routes.storageUnits.getBasePath()} element={<StorageUnits />} />
            <Route path={routes.globalPickingList.getBasePath()} element={<GlobalPickingList />} />
            <Route path={routes.profile.getBasePath()} element={<Profile />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
