import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { OptionsTypeInteger } from 'noddi-async/src/types';
import { CarWheelSetForLabelPrint } from 'noddi-async/src/types/workerapp/routeLabels';
import { ApiErrorMessage, InfoPage, LoadingScreen } from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';

import { PrintLabelItem } from './PrintLabelItem';

type PrintLabelsProps = {
  date: Date;
  serviceWorker?: OptionsTypeInteger;
  storageFacilityId: number;
};

export const PrintLabelsList = ({ date, serviceWorker, storageFacilityId }: PrintLabelsProps) => {
  const { data, error, isPending } = noddiAsync.useGet({
    type: URLKeys.getRouteLabels,
    input: {
      storageFacilityId,
      fromDate: format(date, DateFormats.DASHED_DATE_ISO_8601),
      toDate: format(date, DateFormats.DASHED_DATE_ISO_8601),
      serviceWorkerIds: serviceWorker ? [serviceWorker.value] : []
    }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ApiErrorMessage error={error} />;
  }

  if (!data?.length) {
    return <InfoPage title={t`No labels found`} message={t`Try choosing another date or storage facility`} />;
  }
  // Todo : Backend should return labeldata directly, so we dont have to map type here, will be fixed later
  // Also then we dont need the full car object.
  return (
    <div className='grid grid-cols-1 gap-4 lg:grid-cols-2'>
      {data.map((routeLabel) => {
        const labelPrintData: CarWheelSetForLabelPrint[] = routeLabel.data.map(({ carWheelSet, car, userGroup }) => ({
          id: carWheelSet.id,
          workAppUrl: carWheelSet.workAppUrl,
          userGroupName: userGroup.name,
          type: carWheelSet.type,
          car: car
        }));

        return <PrintLabelItem key={routeLabel.route?.id} route={routeLabel.route} data={labelPrintData} />;
      })}
    </div>
  );
};
