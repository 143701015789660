import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { CarWheelSetForLabelPrint } from 'noddi-async/src/types/workerapp/routeLabels';
import { NoddiButton, NoddiContainer, NoddiTable, usePagination } from 'noddi-ui';
import { useState } from 'react';

import { PrintLabelItem } from '../PrintLabels/PrintLabelItem';

const getTableHeaders = () => [t`License plate`, t`Tire type`];

const PrintLabelRegnumber = () => {
  const [toPrintList, setToPrintList] = useState<CarWheelSetForLabelPrint[]>([]);
  const { page, setPage, pageSize, setPageSize, search, setSearch } = usePagination();

  const { data, isPending } = noddiAsync.useGet({
    type: URLKeys.getPaginatedPrintingLabels,
    input: { page, pageSize, search }
  });

  const tableItems = data?.results.map((item) => {
    return {
      renderItems: [item.car.licensePlateNumber, item.type.label],
      item
    };
  });

  return (
    <NoddiContainer header={t`Print labels license plate search`}>
      <div className='flex flex-col gap-4'>
        <p>{t`Click on rows to add cars to list of items to print. Four labels will be printed per car`}</p>
        <div className='flex w-full gap-10'>
          <NoddiTable
            page={page}
            items={tableItems}
            setPage={setPage}
            pageSize={pageSize}
            onRowClick={(item) => {
              setToPrintList([...toPrintList, item]);
            }}
            setPageSize={setPageSize}
            count={data?.count}
            headers={getTableHeaders()}
            isLoading={isPending}
            searchProps={{
              onSearch: (e) => {
                setPage(1);
                setSearch(e.target.value ?? '');
              },
              placeholder: t`Search by license plate`
            }}
          />
          <div className='flex h-fit items-end gap-4'>
            <PrintLabelItem data={toPrintList} />
            <NoddiButton
              onClick={() => {
                setToPrintList([]);
              }}
              variant='secondary'
            >
              <Trans>Clear</Trans>
            </NoddiButton>
          </div>
        </div>
      </div>
    </NoddiContainer>
  );
};

export default PrintLabelRegnumber;
