import { t } from '@lingui/macro';
import { TextField } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { OptionsTypeInteger } from 'noddi-async/src/types';
import {
  ApiErrorMessage,
  LoadingScreen,
  NoddiAutocomplete,
  NoddiContainer,
  NoddiDatePicker,
  NoddiSelect
} from 'noddi-ui';
import { useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

import { PrintLabelsList } from './PrintLabelsList';

export const PrintLabelWrapper = () => {
  const [date, setDate] = useState(new Date());
  const [serviceWorker, setServiceWorker] = useState<OptionsTypeInteger>();
  const ramstad24Id = 1;

  const [storageId, setStorageId] = useQueryParam('storageId', NumberParam);

  const {
    data: serviceWorkerData,
    isPending: isServiceWorkerPending,
    error: IsServiceWorkerError
  } = noddiAsync.useGet({
    type: URLKeys.getServiceWorkers,
    input: {}
  });

  const {
    data: storageFacilityData,
    error: isStorageFacilityError,
    isPending: isStorageFacilityPending
  } = noddiAsync.useGet({
    type: URLKeys.getPaginatedStorageFacilities,
    input: { page: 1, pageSize: 1000 }
  });

  if (isServiceWorkerPending || isStorageFacilityPending) {
    return <LoadingScreen />;
  }
  if (IsServiceWorkerError) {
    return <ApiErrorMessage error={IsServiceWorkerError} />;
  }
  if (isStorageFacilityError) {
    return <ApiErrorMessage error={isStorageFacilityError} />;
  }

  const serviceWorkerOptions = serviceWorkerData.map((item) => ({
    value: item.id,
    label: item.user.name
  }));

  const storageFacilityOptions = storageFacilityData.results.map((facility) => ({
    label: facility.name,
    value: facility.id
  }));

  const storageFacilityId = storageId ?? ramstad24Id;

  return (
    <NoddiContainer header={t`Label printer`}>
      <div className='flex flex-col gap-8'>
        <div className='flex max-w-lg items-end gap-6'>
          <NoddiAutocomplete
            options={serviceWorkerOptions}
            fullWidth
            onChange={(_, newvalue) => setServiceWorker(newvalue as OptionsTypeInteger)}
            renderInput={(params) => <TextField {...params} label={t`Search for your name`} />}
          />
          <NoddiDatePicker label={t`Choose date`} value={date} onChange={(date) => setDate(date!)} />
          <NoddiSelect
            options={storageFacilityOptions}
            fullWidth
            label={t`Storage units`}
            value={storageFacilityId}
            onChange={(e) => {
              setStorageId(e.target.value as number);
            }}
          />
        </div>
        <PrintLabelsList storageFacilityId={storageFacilityId} date={date} serviceWorker={serviceWorker} />
      </div>
    </NoddiContainer>
  );
};
