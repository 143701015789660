import { Trans } from '@lingui/macro';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { EmailLogin, NoddiIcon, NoddiLogo, SectionSpacer, useGetScreenDimensions, useLoginRedirect } from 'noddi-ui';
import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 330px;
  max-width: 550px;
  @media (width < 550px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 24px 24px 0px 24px;
`;

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const { screenSize } = useGetScreenDimensions();

  const containerHeight = screenSize.height * 0.75;

  useLoginRedirect({ homePath: '/' });

  return (
    <Stack justifyContent='center' alignItems='center' minHeight={containerHeight} marginY={10}>
      <Container>
        <Card elevation={16}>
          <Header>
            <div style={{ margin: 'auto 0' }}>
              <Typography variant='h3'>
                <Trans>Login</Trans>
              </Typography>
            </div>
            <NoddiLogo />
          </Header>

          <CardContent style={{ paddingTop: '0px' }}>
            <SectionSpacer>
              <div style={{ width: '100%' }}>
                <EmailLogin setErrorMessage={setErrorMessage} />
              </div>
            </SectionSpacer>

            {errorMessage && (
              <p className='mt-3 text-start text-signal-danger'>
                <NoddiIcon name='Exclamation' className='mb-1' size='medium' /> {errorMessage}
              </p>
            )}
          </CardContent>
        </Card>
      </Container>
    </Stack>
  );
};

export default Login;
