import { useLanguageContext } from 'noddi-provider';
import { NoddiLanguagePicker, VerticalLayout } from 'noddi-ui';
import { useEffect } from 'react';

import { getNavItems } from '../NavItems';
import { getSupportedLocales } from './App';

/**
 * A wrapper component for VerticalLayout that handles translation of navigation items.
 *
 * The reason for this component's existence is to have let the navItems be translated, which wasn't the
 * case with the previous implementation of VerticalLayout which only read the navItems on mount
 */
const TranslatedVerticalLayout = () => {
  const { currentLanguage } = useLanguageContext();

  let navItems = getNavItems();
  let supportedLanguages = getSupportedLocales();

  useEffect(() => {
    navItems = getNavItems();
    supportedLanguages = getSupportedLocales();
  }, [currentLanguage]);

  return (
    <VerticalLayout
      navItems={navItems}
      alwaysShowMobileNav
      LanguagePicker={<NoddiLanguagePicker supportedLanguages={supportedLanguages} />}
    />
  );
};

export default TranslatedVerticalLayout;
