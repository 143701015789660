import { t } from '@lingui/macro';

import routes from './src/appRoutes';

export const getNavItems = () => [
  {
    items: [
      {
        path: routes.home.getPath(),
        title: t`Printing of labels`
      }
    ]
  },
  {
    items: [
      {
        path: routes.storageUnits.getPath(),
        title: t`Storage units`
      }
    ]
  },
  {
    items: [
      {
        path: routes.globalPickingList.getPath(),
        title: t`Global picking list`
      }
    ]
  },
  {
    items: [
      {
        path: routes.printLabelsLicencePlateNumber.getPath(),
        title: t`Print labels license plate search`
      }
    ]
  }
];
