import * as ReactQuery from '@tanstack/react-query';
import { noddiAsync } from 'noddi-async';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.css';

noddiAsync.init({
  reactQuery: ReactQuery,
  baseUrl: import.meta.env.VITE_APP_BE_URL ?? ''
});

createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
